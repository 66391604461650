import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/header.css"; // Ensure the CSS file exists

const Header = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50); // Adjust the scroll threshold as needed
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navItems = [
    { url: "/home-new-1", display: "BH Services", isDropdown: true },
    { url: "/home-new-1#clinicPharma", display: "BH Clinics" },
    { url: "/elder-care", display: "Elder Care" },
    { url: "/home-new-1", display: "Speciality OPD" },
  ]; 

  const dropdownItems = [
    { url: "/women-care", display: "Women Care" },
    { url: "/lab-tests", display: "Lab Tests" },
    { url: "/radiology", display: "Radiology" },
    { url: "/diet-and-nutrition-program", display: "Diet & Nutrition" },
    { url: "/ai-smart-screening", display: "Smart Screening" },
    { url: "/adult-vaccination-program", display: "Adult Vaccines" },
    { url: "/yoga", display: "Physical Wellbeing" },
    { url: "/physiotherapy-services", display: "Physiotherapy" },
  ];

  return (
    <header className={`header ${scrolled ? "scrolled" : ""}`}>
      <nav className="nav">
        {/* Logo */}
        <div className="nav-logo">
        <a href="https://bridgehealth.in/home-new-1" rel="noopener noreferrer">
          <img
            src="/images/new-website/bhlogo.webp"
            alt="header"
            className="nav-logo-img"
          />
        </a>
        </div>

        {/* Hamburger Menu Button */}
        <button
          className="nav-hamburger"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="nav-hamburger-icon"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5m-16.5 5.25h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>

        {/* Desktop Navigation */}
        <ul className="nav-list nav-list-desktop">
          {navItems.map((item, index) => (
            <li
              key={index}
              className={`nav-item-1 ${
                item.isDropdown ? "nav-item-dropdown" : ""
              }`}
              onMouseEnter={() => item.isDropdown && setIsDropdownOpen(true)}
              onMouseLeave={() => item.isDropdown && setIsDropdownOpen(false)}
            >
              <Link to={item.url} className="nav-link">
                {item.display}
              </Link>
              {item.isDropdown && isDropdownOpen && (
                <ul className="dropdown-menu">
                  {dropdownItems.map((dropdownItem, idx) => (
                    <li key={idx} className="dropdown-item">
                      <Link to={dropdownItem.url} className="dropdown-link">
                        {dropdownItem.display}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </nav>

      {/* Mobile Navigation */}
          {isMenuOpen && (
            <div className="nav-mobile">
              <ul className="nav-list nav-list-mobile">
                {navItems.map((item, index) => (
                  <li key={index} className="nav-item">
                    <Link
                      to={item.url}
                      className="nav-link"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      {item.display}
                    </Link>
                    {item.isDropdown && (
                      <>
                        <button
                          className="dropdown-toggle"
                          onClick={() =>
                            setIsDropdownOpen((prev) =>
                              prev === index ? null : index
                            )
                          }
                        >
                          ▼
                        </button>
                        {isDropdownOpen === index && (
                          <ul className="dropdown-menu">
                            {dropdownItems.map((dropdownItem, idx) => (
                              <li key={idx} className="dropdown-item">
                                <Link
                                  to={dropdownItem.url}
                                  className="dropdown-link"
                                  onClick={() => setIsMenuOpen(false)}
                                >
                                  {dropdownItem.display}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
    </header>
  );
};

export default Header;