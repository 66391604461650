import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/new-website.css"
import Footer from "../layout/footerUpdated";
import Header from "../layout/headerHome";
import SliderLab from "../element/component/Banner_New_Slider";
import ServiceSection from "../element/component/ServiceSectionAll";
import FAQComponent from '../element/component/faq';
import WhyLab from '../element/component/why';
import TestimonialSlider from "../element/component/testimonial-slider";
import ReachToUsForm from "../element/ReachToUsForm";
import Popup_Main from "../element/component/Popup_Main";

const YogaPage = () => {

  const [isOpen, setIsOpen] = useState(false);
  const Counter = ({ targetValue, duration }) => {
    const [count, setCount] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
  
    useEffect(() => {
      let startValue = 0;
      const increment = targetValue / (duration / 50);
  
      const updateCounter = () => {
        startValue += increment;
        if (startValue >= targetValue) {
          setCount(Math.ceil(targetValue));
          setIsAnimating(false); // End animation
        } else {
          setCount(Math.floor(startValue));
          setIsAnimating(true); // Start animation
          requestAnimationFrame(updateCounter);
        }
      };
  
      setIsAnimating(true); // Initialize animation
      updateCounter();
    }, [targetValue, duration]);
  
    return <h3 className={isAnimating ? "animate-counter" : ""}>{count}%</h3>;
  };
  
  const whyData = [
    { title: "Highly qualified experienced Yoga Experts", image: "/images/new-website/yoga/yogapage-expert.webp" },
    { title: "Personalized Treatments Plans", image: "/images/new-website/yoga/personalized-treatment.webp" },
    { title: "Comprehensive Holistic Approach", image: "/images/new-website/yoga/comprehensive-holistic-approach.webp" },
    { title: "Convenient at-Home Services", image: "/images/new-website/yoga/yoga-at-home.webp" },
    { title: "Flexible Scheduling", image: "/images/new-website/yoga/flexible-schedule.png" },
    { title: "Cost Effective Solution", image: "/images/new-website/yoga/cost-effective-solution.webp" },
];

// Slider settings defined here
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: "15%",
        autoplay: true,
        autoplaySpeed: 3000,
        prevArrow: (
        <div className="slick-prev">
            <i className="fa fa-angle-left" aria-hidden="true"></i>
        </div>
        ),
        nextArrow: (
        <div className="slick-next">
            <i className="fa fa-angle-right" aria-hidden="true"></i>
        </div>
        ),
        responsive: [
          {
            breakpoint: 1024, // Tablet view
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768, // Mobile view
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
    };

    const slides = [
        {
          desktop: "/images/new-website/yoga/yoga-4.webp",
          mobile: "/images/new-website/yoga/yoga-5.webp",
        },
        {
          desktop: "/images/new-website/yoga/yoga-5.webp",
          mobile: "/images/new-website/yoga/yoga-5.webp",
        },
        {
          desktop: "/images/new-website/yoga/yoga-6.webp",
          mobile: "/images/new-website/yoga/yoga-6.webp",
        },
        {
          desktop: "/images/new-website/yoga/yoga-7.webp",
          mobile: "/images/new-website/yoga/yoga-7.webp",
        },
        {
            desktop: "/images/new-website/yoga/yoga-8.webp",
            mobile: "/images/new-website/yoga/yoga-8.webp",
        },
      ];

    const yogaTypes = [
        {
            name: "Group School",
            desc: "Join our dynamic group sessions, including Hatha Yoga and Vinyasa Yoga, designed to foster community and support.",
            image: "/images/new-website/yoga/yoga-type-1.webp",
          },
          {
            name: "Individual Classes",
            desc: "Whether it's to deepen your yoga practice or focus on specific goals, seek one-on-one attention and a customised approach. ",
            image: "/images/new-website/yoga/yoga-type-2.webp",
          },
          {
            name: "Online Classes",
            desc: "If you're searching for yoga near me but prefer the convenience and privacy of practing at home, look no further! ",
            image: "/images/new-website/yoga/yoga-type-3.webp",
          },
    
      ];

  const testimonials = [
    {
      content:
        "Bridge health offers services at affordable cost. Akshata at the front desk is very cooperative and good at coordinating and follow-ups. The staff are well spoken and carried out all tests with patience.",
      name: "Akshata J Lokhande",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "I am Prof Sundararaj living at PRIMUS REFLECTION. The staff are very courteous with a smile on their faces, including all the doctors. Special thanks to Ms. Akshita!",
      name: "Sundar Raj",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Good , clean hygiene place ... Feels like your are at home with the services that provide... I have enrolled for gym here.. And the trainers are thorough professionals.. And the staff I have interacted with them during the seminars or presentations they are knowledgeable",
      name: "Shiva Kumar S",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "From sattva apartment Bridge healthy is useful for our patients.Mahendra the nursing staff is good and courteous.",
      name: "Susheela Sharan",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Laxman is an excellent nurse looking after the retired community. Gentle in taking blood. Very polite and efficient.",
      name: "Dipak Rao",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
  ];

  const faqs = [
    {
      question: "What to look for to choose the best yoga class?",
      answer:
        "Look for experienced instructors, personalised attention, a variety of styles, and a welcoming atmosphere that suits your fitness level and goals – all that you’ll get at Bridge Health.",
    },
    {
      question: "Which yoga is best for beginners?",
      answer: "Hatha Yoga is ideal for beginners due to its slow pace, simple poses, and emphasis on foundational techniques.",
    },
    {
      question: "How much do yoga classes cost in Bangalore?",
      answer: "Yoga classes in Bangalore typically range from ₹500 to ₹1,500 per session, depending on the studio and class format.",
    },
    {
      question: "Does yoga help lose weight?",
      answer: "Yes, yoga can aid in weight loss by boosting metabolism, improving flexibility, and reducing stress, which influences weight management.",
    },
    {
      question: "Which yoga is best for weight loss?",
      answer: "Vinyasa Yoga is effective for weight loss as it combines flowing sequences with a faster pace, helping to burn calories and increase stamina. ",
    },
  ];


  const [isMobile, setIsMobile] = useState(false);
  const sectionRefs = useRef([]); // Refs for multiple sections/cards
  const [hasAnimated, setHasAnimated] = useState([]); // Animation tracking for cards

  // Resize event listener to detect mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initialize on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Scroll Animation Logic
  useEffect(() => {
    const handleScroll = () => {
      const updatedVisibility = sectionRefs.current.map((ref) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;

          // Element becomes visible when its top is within viewport
          return rect.top <= windowHeight - 50;
        }
        return false;
      });

      setHasAnimated((prev) =>
        updatedVisibility.map((visible, index) => prev[index] || visible)
      );
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger on mount to check initial visibility
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const cardsData = [
    { title: "Muscle Pain", image: "/images/new-website/yoga/muscle-pain.webp" },
    { title: "Bone / Joint Pains", image: "/images/new-website/yoga/joint-pain.webp" },
    { title: "Gut / Digestive Health", image: "/images/new-website/yoga/digestive-health.webp" },
    { title: "Heart Health", image: "/images/new-website/yoga/healthy-heart.webp" },
    { title: "Obesity", image: "/images/new-website/yoga/obesity.webp" },
    { title: "Diabetes", image: "/images/new-website/yoga/lifestyle-yoga.webp" },
    { title: "PCOS", image: "/images/new-website//yoga/women-health.webp" },
    { title: "Mental & Emotional", image: "/images/new-website/yoga/brain-health.webp" },
  ];

  const styles = {
    headingwoman1: {
        fontSize: "50px",
        color:"#0d0d0d",
        fontWeight: "600",
        lineHeight: "1.2",
        textAlign: "left",
        width: "100%"
      }
      
  };

  const nablslides = [
    "/images/Bridge-Health-ECG-1.webp",
    "/images/Bridge-Health-Labs-1.webp",
    "/images/BH_lab_closeup-1.webp",
  ];

  const tests = [
    {
      icon: "/images/blood-test.webp",
      title: "Complete Blood Count (CBC)",
      testsIncluded: "29 Parameters Included",
      price: "₹840",
      originalPrice: "₹2200",
      discount: "23% off",
    },
    {
      icon: "/images/sugar-blood-test.webp",
      title: "HbA1c, Glycated Haemoglobin",
      testsIncluded: "3 Parameters Included",
      price: "₹840",
      originalPrice: "₹2200",
      discount: "23% off",
    },
    {
      icon: "/images/kidney-test.webp",
      title: "Liver Function Test (LFT)",
      testsIncluded: "29 Parameters Included",
      price: "₹840",
      originalPrice: "₹2200",
      discount: "23% off",
    },
    {
      icon: "/images/kidney-test.webp",
      title: "Liver Function Test (LFT)",
      testsIncluded: "29 Parameters Included",
      price: "₹840",
      originalPrice: "₹2200",
      discount: "23% off",
    },
  ];

  return (
    <div>
      <Header/>
      <div className={`landingcontainer ${isMobile ? "mobile" : ""}`}>
        <div className={`leftContainer ${isMobile ? "mobile" : ""}`}>
          <h1>
          Experience {" "}
            <span className="highlight1">Wellness, Embrace Life</span> with Yoga at Bridge H﻿ealth
          </h1>
          <p className="paragraph-left">Discover yoga exercises tailored to support your wellbeing - anytime, anywhere </p>
          <button className="buttonlanding" onClick={()=>setIsOpen(true)}>Book Your Free Consultation</button>
        </div>
        <div className="rightContainer">
          <img
            src="/images/new-website/yoga/yoga-landing.webp"
            alt="yoga-landing"
            className="imagelanding imageFadeInUp"
          />
        </div>
      </div>
      
            <section>
            <div className="rightstrongcontainer">
            <h2 className="heading1">
            Personalised
              <span className="highlight1"> Offline & Online Yoga Classes</span>
              <span className="line-break-heading"></span> for all Your Health Needs
            </h2>
            </div>
              <ServiceSection
                cardsData={cardsData}
                isMobile={isMobile}
                highlight="For All Your Health Needs"
                heading="Personalised Offline & Online Yoga Classes"
                customClass="WellnessSectionWomen"
              />
              <div className="service-section-button">
                <button className="buttonlanding" onClick={()=>setIsOpen(true)}>Request a Call Back</button>
              </div>
            </section>
            
      <section>
        <div className="yoga-types">
          <div className="rightstrongcontainer">
            <h2 className="heading1">
             Explore
              <span className="highlight1"> Yoga Classes</span>
              <span className="line-break-heading"><br /></span> at Bridge Health, Bangalore
            </h2>
          </div>
          <div className="yoga-type-card-section">
                {yogaTypes.map((yoga, index) => (
                    <div
                    key={index}
                    className="yoga-type-card"
                    >
                    <img src={yoga.image} alt={yoga.name} className="leadership-image" />
                    <h3 className="solutiontext"><span className="solutionbold">{yoga.name.split(" ")[0]}</span>{" "}
                    {yoga.name.split(" ").slice(1).join(" ")}</h3>
                    <p className="yoga-type-desc">{yoga.desc}</p>
                    </div>
                ))}
          </div>
        </div>
      </section>

      <section>
        <div className="yoga-types">
          <div className="yogastudioslider">
            <h2 className="heading1">
            Bridge Health's
              <span className="highlight1"> Yoga Studio</span>
              <span className="line-break-heading"><br /></span> at JP Nagar, Bangalore
            </h2>
          </div>
          <SliderLab sliderSettings={sliderSettings} slides={slides} />
        </div>
      </section>

      <section className="why-choose-section-radio">
          <h2 className="heading1">How Bridge Health Delivers <span className="highlight1">Exceptional Yoga</span> Care</h2>
                    
          <WhyLab
            whyData={whyData} 
            dynamicText="Radiology" 
            dynamicParagraph="We have some of the best specialists who bring years of experience and offer evidence – based treatment to ensure best care for you" 
          />
      </section>
    <section>
        <div>
        <FAQComponent faqs={faqs} />
        </div>
    </section>

    <section>
    <div className="testimonials-container">
      {/* Left Side */}
      <div className="left-section-testimonial">
        <h2>
        Trust Us Based on What Our <span className="highlight1">Patrons Say</span>
        </h2>
        <h3>Overall Ratings</h3>
        <div className="ratings">
          <img
            src="/images/new-website/women-care/google-logo-1.webp"
            alt="Google Logo"
          />
          <div>
            <p>
              4.9{" "}
              <span className="stars">
                ⭐⭐⭐⭐⭐
              </span>
            </p>
            <p>Customer Reviews</p>
          </div>
        </div>
        <a href="https://www.google.com/search?q=bridgehealth&sca_esv=f8c3d57e75161cda&source=hp&ei=k3mQZ5CSHe_G1e8Psu7IkA4&iflsig=AL9hbdgAAAAAZ5CHoz7_cRGSTpek_a5zgM_qrQEKb84T&ved=0ahUKEwjQp-qYxIiLAxVvY_UHHTI3EuIQ4dUDCBk&uact=5&oq=bridgehealth&gs_lp=Egdnd3Mtd2l6IgxicmlkZ2VoZWFsdGgyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESNgdUABY_hpwAHgAkAEAmAHKAaABqg-qAQYwLjExLjG4AQPIAQD4AQGYAgygAuAPwgIIEAAYgAQYsQPCAgsQABiABBixAxiDAcICBRAuGIAEwgILEC4YgAQYsQMYgwHCAg4QLhiABBixAxiDARiKBcICDhAAGIAEGLEDGIMBGIoFwgIIEC4YgAQYsQPCAg4QLhiABBixAxjRAxjHAcICCxAAGIAEGLEDGIoFwgILEC4YgAQYsQMY1AKYAwCSBwYwLjExLjGgB86VAQ&sclient=gws-wiz#lrd=0x3bae1571e6b9bbaf:0xc485c6444a9e56b4,3,,,," target="_blank" rel="noopener noreferrer">
        <button className="buttonmaphome-review">Write a Review</button>
        </a>
      </div>

      {/* Right Side */}
      <div className="right-section-testimonial">
        <div className="testimonial-page">
      <TestimonialSlider testimonials={testimonials} />
    </div>
      </div>
    </div>
    </section>
        <Popup_Main isOpen={isOpen} onClose={() => setIsOpen(false)} >
        <ReachToUsForm submitted={() => setIsOpen(false)} />
        </Popup_Main>
        <Footer />
    </div>
  );
};

export default YogaPage;
