import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/new-website.css"
import Footer from "../layout/footerUpdated";
import Header from "../layout/headerHome";
import FAQComponent from '../element/component/faq';
import WhyLab from '../element/component/why';
import TestimonialSlider from "../element/component/testimonial-slider";
import BloodTestSlider from "../element/component/vaccinationSlider";
import ReachToUsForm from "../element/ReachToUsForm";
import Popup_Main from "../element/component/Popup_Main";


const AdultVaccines = () => {

  const [isOpen, setIsOpen] = useState(false);
  const Counter = ({ targetValue, duration }) => {
    const [count, setCount] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);
  
    useEffect(() => {
      let startValue = 0;
      const increment = targetValue / (duration / 50);
  
      const updateCounter = () => {
        startValue += increment;
        if (startValue >= targetValue) {
          setCount(Math.ceil(targetValue));
          setIsAnimating(false); // End animation
        } else {
          setCount(Math.floor(startValue));
          setIsAnimating(true); // Start animation
          requestAnimationFrame(updateCounter);
        }
      };
  
      setIsAnimating(true); // Initialize animation
      updateCounter();
    }, [targetValue, duration]);
  
    return <h3 className={isAnimating ? "animate-counter" : ""}>{count}%</h3>;
  };
  
  const whyData = [
    { title: "Certified and Trusted Care", image: "/images/new-website/adult-vaccines/certified-doctos.webp" },
    { title: "Personalised Doctor Guidance", image: "/images/new-website/adult-vaccines/doctor-consultation.webp" },
    { title: "Convenient At-Home Services", image: "/images/new-website/adult-vaccines/home-doctor.webp" },];


    const BloodTestsettings = {
      dots: true, 
      infinite: true, 
      speed: 7000, 
      slidesToShow: 2, 
      slidesToScroll: 1,
      autoplay: true, 
      centerMode: true,
      centerPadding: "15%",
      autoplaySpeed: 3000, 
      responsive: [
  
        {
          breakpoint: 768, // Mobile view
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,

          },
        },
      ],
    };

    const yogaTypes = [
        {
            name: "Women’s Health Vaccinations",
            desc: "Prioritise your well-being with vaccines like HPV (for cervical cancer), and Tdap or TD Vaccine, tailored to protect women at every stage. ",
            image: "/images/new-website/adult-vaccines/women-vaccine.webp",
          },
          {
            name: "Senior Citizen Health Vaccinations",
            desc: "Stay protected in your golden years with Shingles, Pneumococcal, and Influenza Vaccine to guard against age-related illnesses. ",
            image: "/images/new-website/adult-vaccines/old-age-vaccines.webp",
          },
          {
            name: "Travel and Routine Vaccinations",
            desc: "Ensure a safe journey and everyday immunity with vaccines like Typhoid Vaccine, MMR, BCG & more.",
            image: "/images/new-website/adult-vaccines/travel-vaccine.webp",
          },
    
      ];

  const testimonials = [
    {
      content:
        "Bridge health offers services at affordable cost. Akshata at the front desk is very cooperative and good at coordinating and follow-ups. The staff are well spoken and carried out all tests with patience.",
      name: "Akshata J Lokhande",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "I am Prof Sundararaj living at PRIMUS REFLECTION. The staff are very courteous with a smile on their faces, including all the doctors. Special thanks to Ms. Akshita!",
      name: "Sundar Raj",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Good , clean hygiene place ... Feels like your are at home with the services that provide... I have enrolled for gym here.. And the trainers are thorough professionals.. And the staff I have interacted with them during the seminars or presentations they are knowledgeable",
      name: "Shiva Kumar S",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "From sattva apartment Bridge healthy is useful for our patients.Mahendra the nursing staff is good and courteous.",
      name: "Susheela Sharan",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Laxman is an excellent nurse looking after the retired community. Gentle in taking blood. Very polite and efficient.",
      name: "Dipak Rao",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
  ];

  const faqs = [
    {
      question: "Where Can I Get Vaccinated for Adults in Bangalore? ",
      answer:
        "You can get adult vaccinations at hospitals, clinics, and government health centers across Bangalore, including Bridge Health Experience Centre, in JP Nagar. Bridge Health also offers at-home adult vaccinations for your convenience.",
    },
    {
      question: "What are the three types and best Flu Vaccines for adults ",
      answer: "The three types of flu vaccines are inactivated influenza vaccines (IIV), recombinant influenza vaccines (RIV), and live attenuated influenza vaccines (LAIV). The quadrivalent vaccine is commonly recommended for broader protection. ",
    },
    {
      question: "What is DPT Vaccine for Adults? ",
      answer: "The DPT vaccine protects against diphtheria, pertussis (whooping cough), and tetanus. For adults, a booster shot called Tdap is recommended.",
    },
    {
      question: "Who Is Eligible for a Shingles Vaccine? ",
      answer: "Adults aged 50 and older and those with weakened immune systems are eligible for the shingles vaccine. ",
    },
    {
      question: "What Are the Side Effects of Adult Vaccinations? ",
      answer: "Common side effects of adult vaccinations include mild pain, redness, swelling at the injection site, low-grade fever, or fatigue, which usually subside in a few days. ",
    },
  ];


  const [isMobile, setIsMobile] = useState(false);
  const sectionRefs = useRef([]); // Refs for multiple sections/cards
  const [hasAnimated, setHasAnimated] = useState([]); // Animation tracking for cards

  // Resize event listener to detect mobile view
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Initialize on mount
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Scroll Animation Logic
  useEffect(() => {
    const handleScroll = () => {
      const updatedVisibility = sectionRefs.current.map((ref) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          const windowHeight =
            window.innerHeight || document.documentElement.clientHeight;

          // Element becomes visible when its top is within viewport
          return rect.top <= windowHeight - 50;
        }
        return false;
      });

      setHasAnimated((prev) =>
        updatedVisibility.map((visible, index) => prev[index] || visible)
      );
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Trigger on mount to check initial visibility
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const tests = [
    {
      icon: "/images/blood-test.webp",
      title: "HPV Vaccine",
      testsIncluded: "Protects against strains of human papillomavirus causing cervical, anal, mouth, and throat cancers. ",
      price: "₹3000",
      for: "Men, Women",
      start: "Starts"
    },
    {
      icon: "/images/sugar-blood-test.webp",
      title: "Tdap or Td Vaccine",
      testsIncluded: "Shields against tetanus, diphtheria, and whooping cough, critical for prenatal and postpartum health.",
      price: "₹1500",
      for: "Women",
      start: "Starts"
    },
    {
      icon: "/images/kidney-test.webp",
      title: "Herpes Zoster/Shingles Vaccine",
      testsIncluded: "Prevents painful shingles rash and long-term nerve complications. ",
      price: "₹6000",
      for: "Older Adults",
      start: "Starts"
    },
    {
      icon: "/images/kidney-test.webp",
      title: "Pneumococcal Vaccine",
      testsIncluded: "Protects older adults from pneumonia, blood infections, and meningitis. ",
      price: "₹4000",
      for: "Older Adults",
      start: "Starts"
    },

    {
      icon: "/images/kidney-test.webp",
      title: "Influenza Vaccine",
      testsIncluded: "Defends against the flu, a highly contagious virus causing respiratory illnesses. ",
      price: "₹1000",
      for: "Men, Women",
      start: "Starts"
    },

    {
      icon: "/images/kidney-test.webp",
      title: "Typhoid Vaccine",
      testsIncluded: "Protects individuals from typhoid fever caused by contaminated food and water.",
      price: "₹2000",
      for: "All",
      start: "Starts"
    },
  ];

  return (
    <div>
      <Header/>
      <div className={`landingavcontainer ${isMobile ? "mobile" : ""}`}>
        <div className={`leftContainer ${isMobile ? "mobile" : ""}`}>
          <h1 className="adult-landing-header">
          Shield Your Health with Bridge Health’s {" "}
            <span className="highlight1"> Comprehensive Adult Vaccination</span> Services
          </h1>
          <p className="paragraph">At Bridge Health, our certified physiotherapists offer targeted treatment plans to alleviate pain, restore function,
          and enhance mobility. </p>
          <button className="buttonlanding" onClick={()=>setIsOpen(true)}>Request a Call Back</button>
        </div>
        <div className="rightContainer">
          <img
            src="/images/new-website/adult-vaccines/adult-vaccines.webp"
            alt="adult-vaccine-landing"
            className="adult-vaccine-landing imageFadeInUp"
          />
        </div>
      </div>

      <section>
        <div className="yoga-types-adult">
          <div className="rightstrongcontainer">
            <h2 className="heading1">
              <span className="highlight1">Adult Vaccination Packages</span>
              <span className="line-break-heading"><br /></span> at Bridge Health
            </h2>
          </div>
          <div className="yoga-type-card-section">
                {yogaTypes.map((yoga, index) => (
                    <div
                    key={index}
                    className="adult-type-card"
                    >
                    <img src={yoga.image} alt={yoga.name} className="leadership-image" />
                    <div className="physio-types-desc">
                    <h3 className="solutiontext"><span className="solutionbold">{yoga.name.split(" ")[0]}</span>{" "}
                    {yoga.name.split(" ").slice(1).join(" ")}</h3>
                    <p className="yoga-type-desc">{yoga.desc}</p>
                    </div>
                    </div>
                ))}
          </div>
        </div>
  </section>  

      <section>
        <div className={`strongcontainer-adult ${isMobile ? "mobile" : ""}`}>
          <div className="rightstrongcontainer">
            <h2 className="heading1">
            Our Most
              <span className="highlight1"> Popular Vaccines:</span>
              <span className="line-break-heading"><br /></span> Prioritise Your Health Today!  
            </h2>
            <div className="test-slider-container-adult">
            <BloodTestSlider tests={tests} BloodTestsettings={BloodTestsettings} />
            </div>
          </div>
        </div>
      </section>

      <section className="why-choose-section-adult">
          <h2 className="heading1">Why Choose <span className="highlight1">Bridge Health</span> for Adult Vaccination in Bangalore</h2>
                    
          <WhyLab
            whyData={whyData} 
            dynamicText="Radiology" 
            dynamicParagraph="We have some of the best specialists who bring years of experience and offer evidence – based treatment to ensure best care for you" 
          />
      </section>
    <section>
        <div>
        <FAQComponent faqs={faqs} />
        </div>
    </section>

    <section>
    <div className="testimonials-container">
      {/* Left Side */}
      <div className="left-section-testimonial">
        <h2>
        Trust Us Based on What Our <span className="highlight1">Patrons Say</span>
        </h2>
        <h3>Overall Ratings</h3>
        <div className="ratings">
          <img
            src="/images/new-website/women-care/google-logo-1.webp"
            alt="Google Logo"
          />
          <div>
            <p>
              4.9{" "}
              <span className="stars">
                ⭐⭐⭐⭐⭐
              </span>
            </p>
            <p>Customer Reviews</p>
          </div>
        </div>
        <a href="https://www.google.com/search?q=bridgehealth&sca_esv=f8c3d57e75161cda&source=hp&ei=k3mQZ5CSHe_G1e8Psu7IkA4&iflsig=AL9hbdgAAAAAZ5CHoz7_cRGSTpek_a5zgM_qrQEKb84T&ved=0ahUKEwjQp-qYxIiLAxVvY_UHHTI3EuIQ4dUDCBk&uact=5&oq=bridgehealth&gs_lp=Egdnd3Mtd2l6IgxicmlkZ2VoZWFsdGgyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESNgdUABY_hpwAHgAkAEAmAHKAaABqg-qAQYwLjExLjG4AQPIAQD4AQGYAgygAuAPwgIIEAAYgAQYsQPCAgsQABiABBixAxiDAcICBRAuGIAEwgILEC4YgAQYsQMYgwHCAg4QLhiABBixAxiDARiKBcICDhAAGIAEGLEDGIMBGIoFwgIIEC4YgAQYsQPCAg4QLhiABBixAxjRAxjHAcICCxAAGIAEGLEDGIoFwgILEC4YgAQYsQMY1AKYAwCSBwYwLjExLjGgB86VAQ&sclient=gws-wiz#lrd=0x3bae1571e6b9bbaf:0xc485c6444a9e56b4,3,,,," target="_blank" rel="noopener noreferrer">
        <button className="buttonmaphome-review">Write a Review</button>
        </a>
      </div>

      {/* Right Side */}
      <div className="right-section-testimonial">
        <div className="testimonial-page">
      <TestimonialSlider testimonials={testimonials} />
    </div>
      </div>
    </div>
    </section>
        <Popup_Main isOpen={isOpen} onClose={() => setIsOpen(false)} >
        <ReachToUsForm submitted={() => setIsOpen(false)} />
        </Popup_Main>
        <Footer />
    </div>
  );
};

export default AdultVaccines;
