import React, { useState } from "react";

const MedicalMinds = () => {
  const leftProfiles = [
    {
      name: "Dr Dhanya Prabhu Ramadas",
      role: "Consultant Family Physician",
      experience: "17+ Years of Experience",
      bio: "Dr. Dhanya Prabhu Ramadas is a compassionate and dedicated board-certified Family Physician with over 17 years of experience.​",
      image: "/images/new-website/doctor/dhanya-prabhu.webp",
    },

    {
      name: "Dr Preeti Surana (PT)",
      role: "Senior Physiotherapist",
      experience: "20+ Years of Experience",
      image: "/images/new-website/doctor/drpreethi.webp",
    },

    {
      name: "Dr Shweta Goyal",
      role: "Consultant Physician & Diabetes Expert",
      experience: "14+ Years of Experience",
      image: "/images/new-website/doctor/dr-shwetha-new.webp",
    },

    {
      name: "Dr Rekha Venugopal",
      role: "Consultant Gynecologist and Fertility specialist",
      experience: "13+ Years of Experience",
      image: "images/new-website/doctor/Dr. Rekha.webp",
    },

  ];

  const rightProfiles = [
    {
      name: "Rekha Prabhu",
      role: "Senior Nutritionist & Metabolic Health Expert",
      experience: "22+ Years of Experience",
      image: "images/new-website/about-page/Rekha (1).webp",
    },

    {
      name: "Arpitha Mirchandani",
      role: "Senior Psychologist",
      experience: "17+ Years of Experience",
      image: "images/arpitha-mirchandani.webp",
    },

    {
      name: "Roshan Madhusudhan Suvarna",
      role: "Exercise Specialist",
      experience: "9+ Years of Experience",
      image: "images/new-website/doctor/Roshan-bh.webp",
    },

    {
      name: "Pritha Aggarwal",
      role: "Consultant Pathologist",
      experience: "9+ Years of Experience",
      image: "images/new-website/doctor/pritha.webp",
    },
  ];

  const additionalLeftProfiles = [
    {
      name: "Dr Sunila Venikar",
      role: "Consultant Physician",
      experience: "25+ Years of Experience",
      image: "images/new-website/bridgehealth-icon-new.webp",
    },

    {
      name: "Dr Sangita Sutradhar",
      role: "Senior Family Physician",
      experience: "18+ Years of Experience",
      image: "images/new-website/bridgehealth-icon-new.webp",
    },
    {
      name: "Dr Pooja Ghosh",
      role: "Consultant Physician",
      experience: "9+ Years of Experience",
      image: "images/new-website/bridgehealth-icon-new.webp",
    },
    {
      name: "Dr Sujaya Bharathi",
      role: "Senior Dentist",
      experience: "9+ Years of Experience",
      image: "images/new-website/bridgehealth-icon-new.webp",
    },
    {
      name: "Dr Eragul S",
      role: "Consultant Physician",
      experience: "6+ Years of Experience",
      image: "images/new-website/bridgehealth-icon-new.webp",
    },
    {
      name: "Dr Sesha Padmanaban",
      role: "Consultant physician",
      experience: "5+ Years of Experience",
      image: "images/new-website/doctor/Dr. Padmanabham.webp",
    },

    {
      name: "Dr Nischal K",
      role: "Consultant Physician",
      experience: "5+ Years of Experience",
      image: "images/new-website/doctor/nischal.webp",
    },
  ];

  const additionalRightProfiles = [
    {
      name: "Anusha MR",
      role: "Senior Yoga Expert",
      experience: "8+ Years of Experience",
      image: "images/new-website/doctor/Anusha.webp",
    },
    {
      name: "Arvind HR",
      role: "Senior Audiologist",
      experience: "8+ Years of Experience",
      image: "images/new-website/about-page/Arvind.webp",
    },

    {
      name: "Sunil BM",
      role: "Consultant Physician",
      experience: "7+ Years of Experience",
      image: "images/roshan-madhusudhan-suvarna.webp",
    },
  ];

  const [visibleLeftProfiles, setVisibleLeftProfiles] = useState(leftProfiles);
  const [visibleRightProfiles, setVisibleRightProfiles] = useState(rightProfiles);
  const [isExpanded, setIsExpanded] = useState(false);
  const [hoveredProfile, setHoveredProfile] = useState(null);

  const handleLoadMore = () => {
    if (!isExpanded) {
      setVisibleLeftProfiles([...visibleLeftProfiles, ...additionalLeftProfiles]);
      setVisibleRightProfiles([...visibleRightProfiles, ...additionalRightProfiles]);
    } else {
      setVisibleLeftProfiles(leftProfiles);
      setVisibleRightProfiles(rightProfiles);
    }
    setIsExpanded(!isExpanded);
  };

  return (
    <section className="medical-minds-section">
      <div className="medical-minds-container">
        {/* Left Column */}
        <div className="profiles-column colum-border">
          {visibleLeftProfiles.map((profile, index) => (
            <div
              className="profile-card"
              key={index}
              onMouseEnter={() => setHoveredProfile(profile)}
              onMouseLeave={() => setHoveredProfile(null)}
            >
              <img src={profile.image} alt={profile.name} />
              <div className="profile-details">
                <h3>{profile.name}</h3>
                <p>{profile.role}</p>
                {profile.experience && <span>{profile.experience}</span>}
              </div>
              {/* {hoveredProfile === profile && (
                <div className="profile-hover-card">
                  <p>{profile.bio}</p>
                </div>
              )} */}
            </div>
          ))}
        </div>

        {/* Right Column */}
        <div className="profiles-column">
          {visibleRightProfiles.map((profile, index) => (
            <div
              className="profile-card"
              key={index}
              onMouseEnter={() => setHoveredProfile(profile)}
              onMouseLeave={() => setHoveredProfile(null)}
            >
              <img src={profile.image} alt={profile.name} />
              <div className="profile-details">
                <h3>{profile.name}</h3>
                <p>{profile.role}</p>
                {profile.experience && <span>{profile.experience}</span>}
              </div>
              {/* {hoveredProfile === profile && (
                <div className="profile-hover-card">
                  <p>{profile.bio}</p>
                </div>
              )} */}
            </div>
          ))}
        </div>
      </div>

      <div className="load-more-container">
        <button className="load-more-button" onClick={handleLoadMore}>
          {isExpanded ? "Show Less" : "Load More"}
        </button>
      </div>
    </section>
  );
};

export default MedicalMinds;
