import React, { useState } from "react";
import "../../assets/css/new-website.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../layout/headerHome";
import Footer from "../layout/footerUpdated";
import FAQComponent from '../element/component/faq';
import WhyRadiology from '../element/component/why';
import TestimonialSlider from "../element/component/testimonial-slider";
import BloodTestSlider from "../element/component/BloodTestSlider";
import Popup_Main from "../element/component/Popup_Main";
import ReachToUsForm from "../element/ReachToUsForm";


const Radiology = () => {

  const [isOpen, setIsOpen] = useState(false);

  const whyData = [
    { title: "Team of Expert Radiologist", image: "/images/new-website/radiology/radiologist.webp" },
    { title: "Latest Equipments", image: "/images/new-website/radiology/mri.webp" },
    { title: "ISO & NABL Certified Scan Centres", image: "/images/new-website/radiology/sonography.webp" },
  ];

    const BloodTestsettings = {
        dots: true, 
        infinite: true, 
        speed: 7000, 
        slidesToShow: 3, 
        slidesToScroll: 1,
        autoplay: true, 
        autoplaySpeed: 3000, 
        responsive: [
          {
            breakpoint: 900, // Tablet view
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768, // Mobile view
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };

      const cardio = [
        {
          icon: "/images/sugar-blood-test.webp",
          title: "2D ECHO",
          testsIncluded: "Get instant scan copy",
          price: "₹1190",
          originalPrice: "₹1650",
          discount: "23% off",
        },
        {
          icon: "/images/kidney-test.webp",
          title: "TMT",
          testsIncluded: "Get instant scan copy",
          price: "₹1190",
          originalPrice: "₹1650",
          discount: "23% off",
        },
        {
          icon: "/images/blood-test.webp",
          title: "ECG",
          testsIncluded: "Get instant scan copy",
          price: "₹250",
          originalPrice: "₹350",
          discount: "23% off",
        },
      ];

      const xray = [
        {
          icon: "/images/sugar-blood-test.webp",
          title: "Chest X Ray",
          testsIncluded: "Get instant scan copy",
          price: "₹380",
          originalPrice: "₹450",
          discount: "23% off",
        },
        {
          icon: "/images/kidney-test.webp",
          title: "Abdomen X ray",
          testsIncluded: "Get instant scan copy",
          price: "₹380",
          originalPrice: "₹450",
          discount: "23% off",
        },
        {
          icon: "/images/blood-test.webp",
          title: "Hand-AP | OBL",
          testsIncluded: "Get instant scan copy",
          price: "₹380",
          originalPrice: "₹450",
          discount: "23% off",
        },
        {
          icon: "/images/blood-test.webp",
          title: "Thorasic Lamber Spine | AP-Lateral",
          testsIncluded: "Get instant scan copy",
          price: "₹700",
          originalPrice: "₹850",
          discount: "23% off",
        },
      ];

      const ultrasound = [
        {
          icon: "/images/sugar-blood-test.webp",
          title: "Abdoman Pelvic",
          testsIncluded: "Get instant scan copy",
          price: "₹890",
          originalPrice: "₹1350",
          discount: "23% off",
        },
        {
          icon: "/images/kidney-test.webp",
          title: "Ultrasound Scrotum Color Doppler",
          testsIncluded: "Get instant scan copy",
          price: "₹1150",
          originalPrice: "₹1350",
          discount: "23% off",
        },
        {
          icon: "/images/blood-test.webp",
          title: "Arterial Doppler Unilateral Arm/ Leg",
          testsIncluded: "Get instant scan copy",
          price: "₹1490",
          originalPrice: "₹1990",
          discount: "23% off",
        },
        {
          icon: "/images/blood-test.webp",
          title: "Thorasic Lamber Spine | AP-Lateral",
          testsIncluded: "Get instant scan copy",
          price: "₹700",
          originalPrice: "₹850",
          discount: "23% off",
        },
      ];

      const faqs = [
        {
          question: "What is Radiology? ",
          answer:
            "Radiology is a medical specialty that uses imaging techniques like X-rays, CT scans, and MRIs to diagnose and treat diseases.",
        },
        {
          question: "What are the Types of Radiology?",
          answer: "Radiology is broadly categorized into diagnostic radiology (used to identify conditions) and interventional radiology (used for minimally invasive treatments).",
        },
        {
          question: "What are Echo, ECG, and TMT? ",
          answer: "These are tests to assess heart health: Echo (echocardiography) uses ultrasound, ECG records heart electrical activity, and TMT (treadmill test) evaluates heart function during exercise. ",
        },
        {
          question: "How to Prepare for Radiology? ",
          answer: "Follow specific instructions for the test, such as fasting, avoid wearing jewelry, or wearing comfortable clothing, based on your doctor’s guidance.",
        },
        {
          question: "What to Expect During a Radiology Exam? ",
          answer: "You may lie still, follow breathing instructions, or feel mild discomfort, depending on the test. Technicians guide you through the process. ",
        },
        {
          question: "What are the Costs of Radiology Exams in Bangalore? ",
          answer: "Costs vary depending on the type of test, ranging from ₹500 for X-rays to ₹10,000 or more for advanced imaging. ",
        },
      ];

      const testimonials = [
        {
          content:
            "Bridge health offers services at affordable cost. Akshata at the front desk is very cooperative and good at coordinating and follow-ups. The staff are well spoken and carried out all tests with patience.",
          name: "Akshata J Lokhande",
          image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
        },
        {
          content:
            "I am Prof Sundararaj living at PRIMUS REFLECTION. The staff are very courteous with a smile on their faces, including all the doctors. Special thanks to Ms. Akshita!",
          name: "Sundar Raj",
          image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
        },
        {
          content:
            "Good , clean hygiene place ... Feels like your are at home with the services that provide... I have enrolled for gym here.. And the trainers are thorough professionals.. And the staff I have interacted with them during the seminars or presentations they are knowledgeable",
          name: "Shiva Kumar S",
          image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
        },
        {
          content:
            "From sattva apartment Bridge healthy is useful for our patients.Mahendra the nursing staff is good and courteous.",
          name: "Susheela Sharan",
          image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
        },
        {
          content:
            "Laxman is an excellent nurse looking after the retired community. Gentle in taking blood. Very polite and efficient.",
          name: "Dipak Rao",
          image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
        },
      ];

    
  return (
    <>
      <Header />
      <div>
      <div className="landingcontainer-radio">
        <div className="leftContainer">
          <h1 className="landing-heading">
          Explore
            <span className="highlight1"> High-Precision<br/>Radiology</span> Services with us
            
          </h1>
          <p className="paragraph-left">At Bridge Health Radiology, we bring clarity to your 
          health with precise imaging for X-Ray, Ultrasound & Cardiology needs.
          </p>
          <button className="buttonlanding" onClick={()=>setIsOpen(true)}>Request a Call Back</button>
        </div>
        <div className="rightContainer">
          <img
            src="images/new-website/radiology/radiology-landing-bg.webp"
            alt="radiology"
            className="imagelanding-radio imageFadeInUp"
          />
        </div>
      </div>

        {/* Screening Section */}
        <section className="radiology-service">
        <h2 className="heading1">Get <span className="highlight1">Safe and Reliable Radiology</span><br/> Services for End-to-End Health Insights</h2>
          <p>Radiology is the backbone of preventive healthcare, offering early detection and accurate diagnosis to stay ahead of health issues. At Bridge Health radiology, prevention starts with clarity, and we’re here to deliver.<br/></p>
          <div className="screening-cards">
            <div className="screening-card">
              <img src="images/ultrasound-breast.webp" alt="AI Breast Screening" width={80} className="imageFadeInUp"/>
              <h3><span>Ultrasound</span> Breast</h3>
            </div>
            <div className="screening-card">
              <img src="/images/ribbon.webp" alt="AI Knee Assessment" width={80} className="imageFadeInUp"/>
              <h3><span>Ultrasound</span>  Pelvis</h3>
            </div>
            <div className="screening-card">
              <img src="/images/prenatal-care.webp" alt="HbA1c Screening" width={80} className="imageFadeInUp"/>
              <h3><span>Ultrasound</span> Abdomen</h3>
            </div>
            <div className="screening-card">
              <img src="/images/pregnant.webp" alt="HbA1c Screening" width={80} className="imageFadeInUp"/>
              <h3><span>Ultrasound</span> Transvaginal</h3>
            </div>
          </div>
          <button className="callout-button" onClick={()=>setIsOpen(true)}>Schedule your Scan Today</button>
        </section>

        <Popup_Main isOpen={isOpen} onClose={() => setIsOpen(false)} >
        <ReachToUsForm submitted={() => setIsOpen(false)} />
        </Popup_Main>

        {/* X-ray Imaging at Bridge Health */}
        <section>
            <div className="x-ray-section">
                <div className="x-ray-leftcontainer">
                    <h2>Cutting-Edge <span className="cardiology-span">Cardiology</span> Tests</h2>
                    
                    <p>We use state-of-the-art technology to deliver unmatched accuracy in diagnosing and monitoring cardiac conditions.</p>
                </div>
                <div className="x-ray-rightcontainer">
                <div className="test-slider-container">
                  <BloodTestSlider tests={cardio} BloodTestsettings={BloodTestsettings} />
                </div>
                </div>
            </div>
        </section>

        <section className="Cardiology-section">
            <div className="Cardiology-div">
                <h1>X-ray <span>Imaging</span> at Bridge Health</h1>
                <p>Our safe & reliable X-Ray technology provides unmatched accuracy, ensuring you get the answers you need.</p>
                <div className="test-slider-container">
                  <BloodTestSlider tests={xray} BloodTestsettings={BloodTestsettings} />
                </div>
            </div>
        </section>

        <section style={{marginTop:"70px"}} className="Cardiology-section">
            <div className="Cardiology-div">
                <h1><span>Ultrasounds</span> at Bridge Health</h1>
                <p>We utilize advanced technology to provide unparalleled precision in diagnosing and monitoring through ultrasound.</p>
                <div className="test-slider-container">
                  <BloodTestSlider tests={ultrasound} BloodTestsettings={BloodTestsettings} />
                </div>
            </div>
        </section>

        {/* Physio-Smart Section */}
        <section className="book-appointment-section">
            <div className="get-labcontainer">
                <h2 className="heading1">
                How to 
                <span className="highlight1"> Book Appointment</span> at Bridge Health in Bangalore
                </h2>
                
                <div className="get-lab-span">
                <span >Step 1</span>
                <hr className="get-lab-spanborder" />
                <span>Step 2</span>
                <hr className="get-lab-spanborder" />
                <span>Step 3</span>
                </div>

                <div className="get-lab-p">
                <div className="get-labpara1">
                    <div>
                    <img src="/images/new-website/radiology/book-radiology.webp" alt="" width={130}/> 
                    </div>
                    <div>
                    <h1 className="get-lab-heading">Reach Out & Discuss Your Needs</h1>
                    <p>Call us or leave your name and phone number. Our team will connect with you to understand your specific requirements.</p>
                    </div>
                </div>
                <div className="get-labpara1">
                <div>
                    <img src="/images/new-website/radiology/user-radiology.webp" alt=""  width={150}/> 
                    </div>
                    <div>
                    <h1 className="get-lab-heading">Get Details </h1>
                    <p>We’ll share our nearest lab locations, available tests, and charges over the call.</p>
                    </div>
                </div>
                <div className="get-labpara1">
                    <div>
                    <img src="/images/new-website/radiology/health-radiology.png" alt=""  width={150}/> 
                    </div>
                    <div>
                    <h1 className="get-lab-heading">Confirm Your Slot </h1>
                    <p>Pick a date and time that works for you, and we’ll book your appointment right away.</p>
                    </div>
                </div>
            </div>
            </div>
        </section>
]
    <section className="why-choose-section-radio">
    <h2 className="heading1">Why Choose <span className="highlight1">Bridge Health</span> for <br/>Radiology Services</h2>
              
    <WhyRadiology 
      whyData={whyData} 
      dynamicText="Radiology" 
      dynamicParagraph="We have some of the best specialists who bring years of experience and offer evidence – based treatment to ensure best care for you" 
    />
    </section>
    <section>
        <div>
        <FAQComponent faqs={faqs} />
        </div>
    </section>

    <section>
    <div className="testimonials-container">
      {/* Left Side */}
      <div className="left-section-testimonial">
        <h2>
        Trust Us Based on What Our <span>Patrons Say</span>
        </h2>
        <h3>Overall Ratings</h3>
        <div className="ratings">
          <img
            src="/images/new-website/women-care/google-logo-1.webp"
            alt="Google Logo"
          />
          <div>
            <p>
              4.9{" "}
              <span className="stars">
                ⭐⭐⭐⭐⭐
              </span>
            </p>
            <p>Customer Reviews</p>
          </div>
        </div>
        <a href="https://www.google.com/search?q=bridgehealth&sca_esv=f8c3d57e75161cda&source=hp&ei=k3mQZ5CSHe_G1e8Psu7IkA4&iflsig=AL9hbdgAAAAAZ5CHoz7_cRGSTpek_a5zgM_qrQEKb84T&ved=0ahUKEwjQp-qYxIiLAxVvY_UHHTI3EuIQ4dUDCBk&uact=5&oq=bridgehealth&gs_lp=Egdnd3Mtd2l6IgxicmlkZ2VoZWFsdGgyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESNgdUABY_hpwAHgAkAEAmAHKAaABqg-qAQYwLjExLjG4AQPIAQD4AQGYAgygAuAPwgIIEAAYgAQYsQPCAgsQABiABBixAxiDAcICBRAuGIAEwgILEC4YgAQYsQMYgwHCAg4QLhiABBixAxiDARiKBcICDhAAGIAEGLEDGIMBGIoFwgIIEC4YgAQYsQPCAg4QLhiABBixAxjRAxjHAcICCxAAGIAEGLEDGIoFwgILEC4YgAQYsQMY1AKYAwCSBwYwLjExLjGgB86VAQ&sclient=gws-wiz#lrd=0x3bae1571e6b9bbaf:0xc485c6444a9e56b4,3,,,," target="_blank" rel="noopener noreferrer">
        <button className="buttonmaphome-review">Write a Review</button>
        </a>
      </div>

      {/* Right Side */}
      <div className="right-section-testimonial">
        <div className="testimonial-page">
      <TestimonialSlider testimonials={testimonials} />
    </div>
      </div>
    </div>
    </section>

      </div>
      <Footer />
    </>
  );
};

export default Radiology;
