import React, { useState, useEffect } from "react";
import Banner_slider from "../element/component/Banner_New_Slider";
import "../../assets/css/new-website.css"
import Footer from "../layout/footerUpdated";
import Header from "../layout/headerHome";

const HealthcareSection = () => {
  // Define separate hover states for each card
  const [isHoveredVision, setIsHoveredVision] = useState(false);
  const [isHoveredMission, setIsHoveredMission] = useState(false);

  // Board of Directors hover state
  const [hoveredDirectorIndex, setHoveredDirectorIndex] = useState(null);

  // Slider settings defined here
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "15%",
    autoplay: true,
    autoplaySpeed: 3000,
    prevArrow: (
      <div className="slick-prev">
        <i className="fa fa-angle-left" aria-hidden="true"></i>
      </div>
    ),
    nextArrow: (
      <div className="slick-next">
        <i className="fa fa-angle-right" aria-hidden="true"></i>
      </div>
    ),
  };

  // Custom slides for this page
  const slides = [
    {
      desktop: "/images/new-website/about-page/bridge-health-equipment.webp",
      mobile: "/images/new-website/about-page/bridge-health-equipment.webp",
    },
    {
      desktop: "/images/new-website/about-page/Bridge-health-imaging.webp",
      mobile: "/images/new-website/about-page/Bridge-health-imaging.webp",
    },
    {
      desktop: "/images/new-website/about-page/bridge-health-machine.webp",
      mobile: "/images/new-website/about-page/bridge-health-machine.webp",
    },
    {
      desktop: "/images/new-website/about-page/bridge-health-office-new.webp",
      mobile: "/images/new-website/about-page/bridge-health-office-new.webp",
    },
    {
      desktop: "/images/new-website/about-page/Bridge-health-usg.webp",
      mobile: "/images/new-website/about-page/Bridge-health-usg.webp",
    },
    {
      desktop: "/images/new-website/about-page/bridge-health-yoga.webp",
      mobile: "/images/new-website/about-page/bridge-health-yoga.webp",
    },
  ];

  // Board of Directors data
  const directors = [
    {
      name: "Kris Gopalakrishnan",
      title: "Chairman",
      image: "/images/new-website/about-page/Krish.webp",
    },
    {
      name: "KC Ganesh",
      title: "Director",
      image: "/images/new-website/about-page/KC.webp",
    },
    {
      name: "Sheeba D'Mello",
      title: "Director",
      image: "/images/new-website/about-page/Sheeba.webp",
    },
];

const leadership = [
    {
        name: "Karan Verma",
        title: "CEO",
        image: "/images/new-website/about-page/karan.webp",
        linkedIn: "#",
      },
      {
        name: "Bipul Jha",
        title: "Head Operation",
        image: "images/new-website/about-page/bipul.webp",
        linkedIn: "#",
      },
      {
        name: "Jahan Ara",
        title: "CFO",
        image: "images/new-website/about-page/jahan-ara.webp",
        linkedIn: "#",
      },
      {
        name: "Dr. Dhanya Prabhu Ramdas",
        title: "Head Clinical",
        image: "images/new-website/about-page/dhanya.webp",
        linkedIn: "#",
      },
      {
        name: "Jayeeta",
        title: "CTO",
        image: "images/new-website/about-page/jayeeta.webp",
        linkedIn: "#",
      },
      {
        name: "Sunu",
        title: "Head HR",
        image: "images/new-website/about-page/sunu.webp",
        linkedIn: "#",
      },

  ];

  const Clinical = [
    {
        name: "Dr. Sesha Padmanabham",
        title: "MBBS",
        image: "/images/new-website/doctor/Dr. Padmanabham.webp",
        linkedIn: "#",
      },
      {
        name: "Dr. Shweta Goyal",
        title: "MBBS",
        image: "/images/new-website/doctor/Dr. Shweta.webp",
        linkedIn: "#",
      },
      {
        name: "Dr. Nischal K",
        title: "MBBS",
        image: "/images/new-website/about-page/Dr. Nischal.webp",
        linkedIn: "#",
      },
      {
        name: "Dr. Rekha Venugopal",
        title: "Gynaecologist",
        image: "images/new-website/doctor/Dr. Rekha.webp",
        linkedIn: "#",
      },
      {
        name: "Arpitha Mirchandani",
        title: "Psychologist",
        image: "images/new-website/about-page/Arpitha.webp",
        linkedIn: "#",
      },
      {
        name: "Roshan Madhusudhan Suvarna",
        title: "Fitness Expert",
        image: "images/new-website/about-page/Roshan.webp",
        linkedIn: "#",
      },
      {
        name: "Rekha Prabhu",
        title: "Senior Nutritionist & Metabolic Health Expert",
        image: "/images/new-website/about-page/Rekha (1).webp",
        linkedIn: "#",
      },
      {
        name: "Arvind HR",
        title: "Audiologist",
        image: "images/new-website/about-page/Arvind.webp",
        linkedIn: "#",
      },

  ];

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Check if screen size is mobile
    };

    // Initial check and add resize listener
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
    <Header />
    <div className="healthcare-container">
    <h1 className="healthcare-title">
      Discover Our <span className="healthcare-highlight">Mission and Values</span> in <br /> Patient-Centered Healthcare
    </h1>
    <p className="healthcare-description">
      Bridge Health focuses on preventive and proactive healthcare, offering<br /> comprehensive services tailored for both individuals and corporates.
    </p>
    <section className="banner-slider-about">
      <Banner_slider sliderSettings={sliderSettings} slides={slides} />
    </section>
  </div>

  <section className="about-us-section">
    <div className="about-container">
      <div className="about-left">
        <h2 className="about-title">
          Let's Know About Our <span className="about-title-span">Main Goal</span>
        </h2>
        <p className="about-text">
          Bridge Health specializes in preventive and pro-active healthcare, offering a
          host of services to individuals and corporates – from Lab Tests, Doctor
          Consultations, Fitness, Yoga, Nutrition, Adult Vaccinations, Stress & Sleep
          Management, and Emotional Wellness.
        </p>
        <div className="vision-mission-container">
          <div
            className={`vision-mission-card ${isHoveredVision ? 'hovered' : ''}`}
            onMouseEnter={() => setIsHoveredVision(true)}
            onMouseLeave={() => setIsHoveredVision(false)}
          >
            <div className="vision-mission-content">
              <h4 className="vision-mission-title">
                Our <span className="vision-mission-title-span">Vision</span>
              </h4>
              <p className="vision-mission-text">
                “Spread Happiness through Good Health”
              </p>
            </div>
          </div>
          <div
            className={`vision-mission-card ${isHoveredMission ? 'hovered' : ''}`}
            onMouseEnter={() => setIsHoveredMission(true)}
            onMouseLeave={() => setIsHoveredMission(false)}
          >
            <div className="vision-mission-content">
              <h4 className="vision-mission-title">
                Our <span className="vision-mission-title-span">Mission</span>
              </h4>
              <p className="vision-mission-text">
                “Be Your Healthcare Companion for life”
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-right">
        <img
          className="about-right-img"
          src="/images/new-website/about-page/about-page-desc.webp"
          alt="Doctors"
        />
      </div>
    </div>
  </section>

  <section className="board-section">
    <h2 className="heading1">
      Bridge Health's <span className="highlight1">Board of Directors</span>
    </h2>
    <div className="board-container">
      {directors.map((director, index) => (
        <div
          key={index}
          className={`board-card ${hoveredDirectorIndex === index ? 'hovered' : ''}`}
          onMouseEnter={() => setHoveredDirectorIndex(index)}
          onMouseLeave={() => setHoveredDirectorIndex(null)}
        >
          <img
            src={director.image}
            alt={director.name}
            className="board-card-image"
          />
          <div className="board-card-content">
            <h3 className="board-card-name">{director.name}</h3>
            <p className="board-card-designation">{director.title}</p>
          </div>
        </div>
      ))}
    </div>
  </section>

  <section className="leadership-section">
    <h2 className="heading1">
      Bridge Health's <span className="highlight1">Leadership Team</span>
    </h2>
    <div className="about-team-card-section">
      {leadership.map((leader, index) => (
        <div
          key={index}
          className="about-team-card"
        >
          <img src={leader.image} alt={leader.name} className="leadership-image" />
          <div className="team-details-desc">
          <div className="leadership-text">
            <h3 className="team-name">{leader.name}</h3>
            <p className="team-title">{leader.title}</p>
          </div>
          <a
            href={leader.linkedIn}
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-badge"
          >
            <i className="fab fa-linkedin linkedin-logo"></i>
          </a>
          </div>
        </div>
      ))}
    </div>
  </section>

  <section className="clinical-section">
    <h2 className="heading1">
      Bridge Health's <span className="highlight1">Clinical Team</span>
    </h2>
    <div className="about-team-card-section">
      {Clinical.map((clinical, index) => (
        <div
          key={index}
          className="about-team-card"
        >
          <img src={clinical.image} alt={clinical.name} className="clinical-image" />
          <div className="team-details-desc">
          <div className="clinical-text">
            <h3 className="team-name">{clinical.name}</h3>
            <p className="team-title">{clinical.title}</p>
          </div>
          <a
            href={clinical.linkedIn}
            target="_blank"
            rel="noopener noreferrer"
            className="linkedin-badge"
          >
            <i className="fab fa-linkedin linkedin-logo"></i>
          </a>
          </div>
        </div>
      ))}
    </div>
  </section>
  <Footer />
    </>
  );
};

export default HealthcareSection;
