import React, { useEffect, useRef, useState } from "react";
import "../../../assets/css/new-website.css";

export default function HealthCareTemplate() {
  const sectionsRef = useRef([]);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = sectionsRef.current.indexOf(entry.target);
            setActiveIndex(index);
          }
        });
      },
      { threshold: 0.5 }
    );

    sectionsRef.current.forEach((section) => {
      if (section) observer.observe(section);
    });

    return () => {
      observer.disconnect(); // Cleanup observer on unmount
    };
  }, []);

  const sections = [
    {
      title: "Health Checkup",
      imgSrc: "/images/new-website/home-new/health-checkup.webp",
      left: "Doctor Consults",
      leftBottom: "Expert Consults",
      right: "Annual Plans",
    },
    {
      title: "Lab Tests",
      imgSrc: "/images/new-website/home-new/Lab-test.webp",
      imgLink: "/lab-tests",
      left: "Routine Test",
      leftBottom: "Heart Health",
      right: "Advanced Biomarkers",
    },
    {
      title: "Radiology",
      imgSrc: "/images/new-website/home-new/radiology-home.webp",
      imgLink: "/radiology",
      left: "Ultrasound",
      leftBottom: "Xray",
      right: "Echo/TMT",
    },
    {
      title: "Women's Health",
      imgSrc: "/images/new-website/home-new/women-health.webp",
      imgLink: "/women-care",
      left: "Period & PCOS Care",
      leftBottom: "Fertility Wellness",
      right: "Menopause",
    },
    {
      title: "Elder Care",
      imgSrc: "/images/new-website/home-new/Elder-care-landing.webp",
      imgLink: "/elder-care",
      left: "Health Checks",
      leftBottom: "Hearing Care",
      right: "Fall Prevention",
    },
  ];

  const newSections = [
    {
      title: "Physical Wellbeing",
      imgSrc: "/images/new-website/about-page/yoga-home-services.webp",
      imgLink: "/yoga",
      left: "Yoga",
      leftBottom: "Meditation",
      right: "Fitness Plans",
    },
    {
      title: "Food & Sleep",
      imgSrc: "/images/new-website/home-new/food-sleep.webp",
      imgLink: "/diet-and-nutrition-program",
      left: "Diet Plans",
      leftBottom: "Sleep Studies",
      right: "Lifestyle Tips",
    },
    {
      title: "Metabolic Health",
      imgSrc: "/images/new-website/home-new/metabolic-health.webp",
      left: "Diabetes Care",
      leftBottom: "Thyroid Check",
      right: "Gut Health",
    },
    {
      title: "Smart Screenings",
      imgSrc: "/images/new-website/home-new/smart-screening.webp",
      imgLink: "/ai-smart-screening",
      left: "Cancer Screening",
      leftBottom: "Heart Screening",
      right: "Lungs Screening",
    },
    {
      title: "Adult Vaccines",
      imgSrc: "/images/new-website/home-new/adult-vaccine.webp",
      imgLink: "/adult-vaccination-program",
      left: "Flu Shots",
      leftBottom: "Covid Boosters",
      right: "Travel Vaccines",
    },
  ];

  const Card = ({ section, isActive, refHandler }) => (
    <div
      className={`health-checkup ${isActive ? "hovered" : ""}`}
      ref={refHandler}
    >
      <div className="hover-content">
        <div className="hover-text">
          <div className="left-desc top-disc">{section.left}</div>
          <div className="left-desc bottom-disc">{section.leftBottom}</div>
        </div>
        <div className="center-image">
          <a href={section.imgLink || "#"}>
            <img src={section.imgSrc} alt={section.title} />
          </a>
          <h3>{section.title}</h3>
        </div>
        <div className="right-desc">{section.right}</div>
      </div>
    </div>
  );

  return (
    <section>
      {/* Intro Section */}
      <div className="servive">
        <h2 className="heading1">
          Everything You
          <span className="highlight1"> Need for the Whole You</span>
          <br />
          In One Place
        </h2>
        <p className="text-[#0D0D0D] text-center mt-3">
          With our comprehensive health checkups & expert sessions, your
          healthcare needs are in good hands.
        </p>
      </div>

      {/* First Section */}
      <div className="healthcare-container-home">
        <div className="healthcare-grid">
          {sections.map((section, index) => (
            <Card
              key={index}
              section={section}
              isActive={activeIndex === index}
              refHandler={(el) => (sectionsRef.current[index] = el)}
            />
          ))}
        </div>
      </div>

      {/* Second Section */}
      <div className="healthcare-container-home">
        <div className="healthcare-grid">
          {newSections.map((section, index) => (
            <Card
              key={`new-${index}`}
              section={section}
              isActive={false}
              refHandler={null}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
