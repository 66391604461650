import React, { useState } from "react";
import "../../assets/css/new-website.css";
import Footer from "../layout/footerUpdated";
import Header from "../layout/headerHome";
import FAQComponent from '../element/component/faq';
import WhySS from '../element/component/why';
import TestimonialSlider from "../element/component/testimonial-slider";
import ReachToUsForm from "../element/ReachToUsForm";
import Popup_Main from "../element/component/Popup_Main";

const SmartScreenings = () => {
  const [isOpen, setIsOpen] = useState(false);
  const whyData = [
    { title: "World's 1 HbA1c POC Test", image: "/images/new-website/smart-screening/No.1.webp" },
    { title: "IFCC & NGSP Certified, Testing from last 14 years", image: "/images/new-website/smart-screening/certified.webp" },
    { title: "External Quality Assurance data shows as good as lab test", image: "/images/new-website/smart-screening/reliability.webp" },
  ];

  const faqs = [
    {
      question: "What is Smart Face Screening?",
      answer:
        "Smart Face Screening is an AI-powered technology that analyses 25 health parameters through a quick, non-invasive face scan, providing a snapshot of your well-being in 30 seconds.",
    },
    {
      question: "What are the guidelines for AI breast exam screening?",
      answer: "AI breast exam screening, like MammoSmart Pro, is a non-invasive, touch-free, and radiation-free way to detect potential breast abnormalities using thermal imaging technology.",
    },
    {
      question: "Which test is good for knee pain?",
      answer: "Physio-Smart, an AI-powered knee examination tool, provides personalised physiotherapy insights and assessments for knee pain, ensuring effective treatment and recovery.",
    },
    {
      question: "Can I check my HbA1c at home?",
      answer: "Yes, GlucoSmart allows you to check your HbA1c levels at home in just 5 minutes with fast and accurate results.",
    },
    {
      question: "Why must you go for AI-powered screening?",
      answer: "AI-powered screening offers faster, more accurate, and non-invasive health checks, helping you stay informed and proactive about your well-being with minimal effort. ",
    },
  ];

  const testimonials = [
    {
      content:
        "Bridge health offers services at affordable cost. Akshata at the front desk is very cooperative and good at coordinating and follow-ups. The staff are well spoken and carried out all tests with patience.",
      name: "Akshata J Lokhande",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "I am Prof Sundararaj living at PRIMUS REFLECTION. The staff are very courteous with a smile on their faces, including all the doctors. Special thanks to Ms. Akshita!",
      name: "Sundar Raj",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Good , clean hygiene place ... Feels like your are at home with the services that provide... I have enrolled for gym here.. And the trainers are thorough professionals.. And the staff I have interacted with them during the seminars or presentations they are knowledgeable",
      name: "Shiva Kumar S",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "From sattva apartment Bridge healthy is useful for our patients.Mahendra the nursing staff is good and courteous.",
      name: "Susheela Sharan",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
    {
      content:
        "Laxman is an excellent nurse looking after the retired community. Gentle in taking blood. Very polite and efficient.",
      name: "Dipak Rao",
      image: "/images/new-website/bridgehealth-icon-new.webp", // Replace with actual image URL
    },
  ];
  return (
    <>
      <Header />
      <div>
        {/* Hero Section */}
        <section className="landingcontainer">
          <div className="leftContainer">
            <h1>
            Bridge Health's
            <span className="highlight1"> AI Healthcare</span> : Fast, Easy Scans for Instant Insights
            </h1>
            <p className="paragraph">
            From delivering health parameters through a 30 seconds video scan to painless breast health screening and more, explore Bridge Health’s AI-powered technologies
            </p>
            <button className="buttonlanding" onClick={()=>setIsOpen(true)}>Know More</button>
          </div>
          <div className="rightContainer">
          <img
            src="images/new-website/smart-screening/AI-Scanning.webp"
            alt="radiology"
            className="imagelanding imageFadeInUp"
          />
        </div>
        </section>

        {/* Screening Section */}
        <section className="screening-section">
          <h2 className="heading1">Opt for our <span className="highlight1">Smarter Screenings</span><br/> with AI Precision </h2>
          <p className="paragraph">Comprehensive Screening for Cancers and Lifestyle Diseases – Your Path <br />to Proactive Health</p>
          <div className="screening-cards">
            <div className="smart-screening-card">
              <img src="images/face-scan.webp" alt="AI Breast Screening" width={80} className="imageFadeInUp"/>
              <h3><span>Smart</span> Scan</h3>
              <p>30-Sec Video Face Scan</p>
              <button className="secondary-button">Know More</button>
            </div>
            <div className="smart-screening-card">
              <img src="images/mammography.webp" alt="AI Breast Screening" width={80} className="imageFadeInUp"/>
              <h3><span>MammoSmart</span> Pro</h3>
              <p>Touch-Free Breast Check</p>
              <button className="secondary-button">Know More</button>
            </div>
            <div className="smart-screening-card">
              <img src="/images/x-ray.webp" alt="AI Knee Assessment" width={80} className="imageFadeInUp"/>
              <h3><span>Physio</span> Smart</h3>
              <p>AI-Powered Knee Care</p>
              <button className="secondary-button">Know More</button>
            </div>
            <div className="smart-screening-card">
              <img src="/images/sugar-blood-test.webp" alt="HbA1c Screening" width={80} className="imageFadeInUp"/>
              <h3><span>Gluco</span> Smart</h3>
              <p>5-Minute HbA1c Test </p>
              <button className="secondary-button">Know More</button>
            </div>
          </div>
        </section>

        {/* AI Advantage Section */}
        <section className="ai-advantage-section">
          <div className="advantage-text">
            <h2 className="heading1-left">Why Go For <span className="highlight1">AI-Powered Screening</span> Over Traditional Screening? </h2>
            <p className="paragraph">AI-Enabled Screenings: Faster, Smarter, and Non-Invasive Compared to Traditional Methods </p>
            <ul>
              <li><b>Speed</b>: Get instant results with no waiting required </li>
              <li><b>Accuracy</b>: AI delivers precise and consistent results  </li>
              <li><b>Convenience</b>: Enjoy touch-free, painless, radiation-free checks </li>
            </ul>
          </div>
          <div className="advantage-image">
            <img src="images/AI-bridge-health.webp" alt="AI Advantage" className="imageFadeInUp"/>
          </div>
        </section>

        {/* Breast Screening Section */}
        <section className="breast-health-section">
        <h2 className="heading1"> <span className="highlight1">Breast Health</span> Screening</h2>
          <p>Introducing Bridge Health’s Mammo-Smart, the Painless Solution for your <br />preventive Breast Screening</p>
            <div className="mammosmart">
              <h1>MammoSmart Pro</h1>
              <p>Revolutionising Breast Screening with Zero Pain</p>
              <button className="buttonlanding-box" onClick={()=>setIsOpen(true)}>Book Screening</button>
            </div>
          <div className="mammosmart-bg">
            <div className="mammosmart-left">
              <div className="mammostart-col-1">
                <div className="mammostart-ele">
                  <h4>Eligibility</h4>
                  <p>For Women over 18 years of age and above</p>
                </div>
                <div className="mammostart-ele">
                  <h4>Test Time</h4>
                  <p>15-20 Minutes</p>
                </div>
              </div>
              <div className="mammostart-col-1">
                <div className="mammostart-ele">
                  <h4>Reports Given</h4>
                  <p>24 Hrs After the Test</p>
                </div>
                <div className="mammostart-ele">
                  <h4>Expert to Consult</h4>
                  <p>Gynaecologist/Physician</p>
                </div>
              </div>
            </div>
            <div className="mammosmart-right">
              <div className="mammostart-col-3">
                <div className="mammostart-img">
                  <img src="images/MAMO-1024x938.webp" alt="" />
                </div>
              </div>
            </div>
          </div>
            <div className="mammostart-span">
              <span >Step 1</span>
              <hr className="mammostart-spanborder" />
              <p className="para1-mob">In a private room, a thermal device is placed 3 feet away from you, ensuring no touch and privacy</p>
              <span>Step 2</span>
              <hr className="mammostart-spanborder" />
              <p className="para-2-mob">The device captures 5 thermal images of the breast region within 15-20 minutes from different angles</p>
              <span>Step 3</span>
              <hr className="mammostart-spanborder" />
              <p className="para-3-mob">Since it is AI-based, it automatically analyses the images and generate a report</p>
            </div>
            <div className="mammostart-p">
              <p className="para1">In a private room, a thermal device is placed 3 feet away from you, ensuring no touch and privacy</p>
              <p className="para-2">The device captures 5 thermal images of the breast region within 15-20 minutes from different angles</p>
              <p className="para-3">Since it is AI-based, it automatically analyses the images and generate a report</p>
            </div>

        </section>

        <section className="breast-health-section">
        <h2 className="heading1"> Physio-Smart: Quick & Convenient<span className="highlight1"><br />AI powered Knee Assessment</span></h2>
          <p style={{width:"80%"}}>Discover the future of knee health assessment with Physio-Smart, an innovative AI-powered tool designed to conduct comprehensive knee examinations. Say goodbye to invasive and repetitive knee orthopaedic tests – Physio-Smart offers an adaptive, AI-based automated knee physiotherapy system that delivers accurate results efficiently. Take control of your knee health journey today with Physio-Smart </p>
          <div className="mammosmart">
              <h1>Physio Smart</h1>
              <p>Quick & Convenient AI powered Knee Assessment</p>
              <button className="buttonlanding-box" onClick={()=>setIsOpen(true)}>Book Screening</button>
            </div>
          <div className="mammosmart-bg">
            <div className="mammosmart-left">
              <div className="mammostart-col-1">
                <div className="mammostart-ele">
                  <p>Discover the future of knee health assessment with Physio-Smart, an innovative AI-powered tool designed to conduct comprehensive knee examinations. Say goodbye to invasive and repetitive knee orthopaedic tests – Physio-Smart offers an adaptive, AI-based automated knee physiotherapy system that delivers accurate results efficiently. Take control of your knee health journey today with Physio-Smart </p>
                </div>
              </div>
            </div>
            <div className="mammosmart-right">
              <div className="mammostart-col-3">
                <div className="mammostart-img">
                  <img src="images/new-website/smart-screening/knee.webp" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="breast-health-section">
        <h2 className="heading1"> Gluco-Smart<span className="highlight1"> Quick HbA1c Test</span></h2>
          <p style={{width:"80%"}}>Experience the Efficiency of Gluco-Smart – Quick, Accurate HbA1c Testing for Smarter and Proactive Diabetes Management</p>
          <div className="gluco-smart">
            <div className="mammosmart-left">
              <div className="mammostart-img">
                  <img src="images/new-website/smart-screening/Hba1c-test.webp" alt="" />
              </div>
            </div>
            <div className="mammosmart-right">
              <div className="gluco-smar-right">
              <p>The Gluco-Smart Quick HbA1c Test is a state-of-the-art device designed for fast, accurate, and convenient monitoring of HbA1c levels, essential for effective diabetes management. This advanced testing solution delivers reliable results in minutes, empowering patients and healthcare providers to make informed decisions. At our center, we proudly offer this cutting-edge facility, ensuring you receive precise testing and personalized care for better health outcomes.</p>
              <button className="buttonlanding" onClick={()=>setIsOpen(true)}>Get Your Smart Diabetes Check Today</button>
              </div>
            </div>
          </div>
        </section>

        <section className="why-choose-section-ss">
        <h2 className="heading1"> Gluco-Smart<span className="highlight1"> Advantages</span></h2>
          
        <WhySS 
                  whyData={whyData} 
                  />
        </section>

        <section>
            <div>
            <FAQComponent faqs={faqs} />
            </div>
        </section>

        <section>
    <div className="testimonials-container">
      {/* Left Side */}
      <div className="left-section-testimonial">
        <h2 className="heading1-left">
        Trust Us Based on What Our <span className="highlight1">Patrons Say</span>
        </h2>
        <h3>Overall Ratings</h3>
        <div className="ratings">
          <img
            src="/images/new-website/women-care/google-logo-1.webp"
            alt="Google Logo"
          />
          <div>
            <p>
              4.9{" "}
              <span className="stars">
                ⭐⭐⭐⭐⭐
              </span>
            </p>
            <p>Customer Reviews</p>
          </div>
        </div>
        <a href="https://www.google.com/search?q=bridgehealth&sca_esv=f8c3d57e75161cda&source=hp&ei=k3mQZ5CSHe_G1e8Psu7IkA4&iflsig=AL9hbdgAAAAAZ5CHoz7_cRGSTpek_a5zgM_qrQEKb84T&ved=0ahUKEwjQp-qYxIiLAxVvY_UHHTI3EuIQ4dUDCBk&uact=5&oq=bridgehealth&gs_lp=Egdnd3Mtd2l6IgxicmlkZ2VoZWFsdGgyCxAuGIAEGMcBGK8BMgUQABiABDIFEAAYgAQyBRAAGIAEMgUQABiABDIFEAAYgAQyBRAAGIAEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIESNgdUABY_hpwAHgAkAEAmAHKAaABqg-qAQYwLjExLjG4AQPIAQD4AQGYAgygAuAPwgIIEAAYgAQYsQPCAgsQABiABBixAxiDAcICBRAuGIAEwgILEC4YgAQYsQMYgwHCAg4QLhiABBixAxiDARiKBcICDhAAGIAEGLEDGIMBGIoFwgIIEC4YgAQYsQPCAg4QLhiABBixAxjRAxjHAcICCxAAGIAEGLEDGIoFwgILEC4YgAQYsQMY1AKYAwCSBwYwLjExLjGgB86VAQ&sclient=gws-wiz#lrd=0x3bae1571e6b9bbaf:0xc485c6444a9e56b4,3,,,," target="_blank" rel="noopener noreferrer">
        <button className="buttonmaphome-review">Write a Review</button>
        </a>
      </div>

      {/* Right Side */}
      <div className="right-section-testimonial">
        <div className="testimonial-page">
      <TestimonialSlider testimonials={testimonials} />
    </div>
      </div>
    </div>
    </section>

      </div>
      <Popup_Main isOpen={isOpen} onClose={() => setIsOpen(false)} >
        <ReachToUsForm submitted={() => setIsOpen(false)} />
        </Popup_Main>
      <Footer />
    </>
  );
};

export default SmartScreenings;
