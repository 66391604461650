import React from "react";
import Slider from "react-slick"; // Ensure you have `react-slick` installed
import "slick-carousel/slick/slick.css"; // Slick styles
import "slick-carousel/slick/slick-theme.css"; // Optional theme

const BloodTestSlider = ({ tests, BloodTestsettings }) => {
  return (
    <Slider {...BloodTestsettings}>
      {tests.map((test, index) => (
        <div key={index} className="test-card">
          <div className="test-slider-main">
            <div className="vaccination-slider-title-bar">
                <div className="test-slider-title">
                    <h3>{test.title}</h3>
                </div>
                <div className="test-slider-price">
                    <span className="test-slider-original-price">{test.originalPrice}</span>{" "}
                    <span className="test-slider-original-start">{test.start}</span>{" "}
                    <span className="test-slider-current-price">{test.price}</span>{" "}
                    <span className="vaccination-slider-discount">{test.for}</span>
                </div>
            </div>
            <div className="vaccination-slider-details">
                <div className="test-slider-details-header">
                    <div className="test-slider-details-parameter">
                      <p style={{textAlign:"left"}}>{test.testsIncluded}</p>
                    </div>
                    <div className="test-slider-details-others">

                    </div>
                </div>
                <div className="vaccination-slider-buttons">
                    <button className="vaccination-add-button">Book You Slot Now</button>
                </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default BloodTestSlider;